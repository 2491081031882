import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/plugins'
import '@/utils/global-components'
import i18n from './locales'

Vue.config.productionTip = false

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        const vm = vnode.context
        const callback = binding.value

        el.clickOutsideEvent = function(event) {
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
