export default {
    'faq.title': ['Sizning savolin-', 'gizga javoblar'],
    'faq.question_1': 'Qaysi mamlakatlarda hozir uchishim mumkin?',
    'faq.answer_1':
        'Jet Partners bilan siz dunyoning istalgan mamlakatiga kerakli asoslar, shuningdek, Frantsiya, Kipr va Birlashgan Arab Amirliklariga faqat xorijiy pasport va joriy sayyohlik vizasi bilan uchishingiz mumkin.',
    'faq.question_2': 'Chet elda uchish uchun qanday hujjatlar kerak?',
    'faq.answer_2':
        'Agar Jet SharingTM tizimi bõyicha er osti bizning dasturlari haqida gapirish bõlsa, faqat pasport va Schengen viza. Agar siz samolyotda uchib ketadigan joylar alohida xorijiy fuqarolikka ega bõlsa yoki',
    'faq.button': ' Batafsil ',
}
