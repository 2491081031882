import intro from './en-US/intro'
import langChanger from './en-US/lang-changer'
import services from './en-US/services'
import airplanes from './en-US/airplanes'
import features from './en-US/features'
import aboutUs from './en-US/about-us'
import nav from './en-US/nav'
import letsFly from './en-US/lets-fly'
import faq from './en-US/faq'
import fixedInfoBar from './en-US/fixed-info-bar'
import footer from './en-US/footer'
import airplanesPage from './en-US/airplanes-page'
import airplanePage from './en-US/airplane-page'
import faqPage from './en-US/faq-page'
import servicePage from './en-US/service-page'

export default {
    ...intro,
    ...services,
    ...airplanes,
    ...features,
    ...aboutUs,
    ...nav,
    ...letsFly,
    ...faq,
    ...fixedInfoBar,
    ...langChanger,
    ...footer,
    ...airplanesPage,
    ...airplanePage,
    ...faqPage,
    ...servicePage,
}
