<template>
    <div class="slider-container">
        <div class="slider" @touchend="onSwipeEnd">
            <VueSlickCarousel
                @swipe="onSwipeStart"
                @beforeChange="setActiveToCloned"
                ref="airplanesCarousel"
                v-bind="carouselSettings"
            >
                <div class="slider__slide" v-for="airplane of airplanes" :key="airplane.id">
                    <div class="slider__image-wrapper">
                        <img
                            class="slider__image"
                            :src="require(`@/assets/images/catalog/${airplane.img}/slide.jpg`)"
                            :alt="airplane.title"
                        />
                    </div>
                    <div class="slider__card" :class="{ fade: isSwiping }">
                        <div class="slider__info">
                            <h2 class="slider__title">{{ airplane.title }}</h2>
                            <p class="slider__description">{{ airplane.excerptDescription['ru'] }}</p>
                            <Stats class="slider__stats" :stats-list="airplane.statsList" />
                        </div>
                        <AppButton
                            @click="
                                $router.push({
                                    name: 'Airplane',
                                    params: { lang: $i18n.locale, airplaneName: airplane.img },
                                })
                            "
                            size="md-min"
                            class="slider__more"
                            >{{ $t('airplanes.more') }}</AppButton
                        >
                    </div>
                </div>
            </VueSlickCarousel>
            <div class="slider__nav-container">
                <div class="slider__nav">
                    <AppButton size="md-wide" theme="white-accent" @click="showPrev" class="slider__prev">
                        <AppIcon name="arrow-prev" width="57" height="17" />
                    </AppButton>
                    <AppButton size="md-wide" theme="accent" @click="showNext" class="slider__next">
                        <AppIcon name="arrow-right" width="57" height="17" />
                    </AppButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import airplanesData from '@/views/Airplanes/airplanes.json'

import Stats from '@/components/Stats'

export default {
    components: {
        VueSlickCarousel,
        Stats,
    },
    data() {
        return {
            isSwiping: false,
            swipingTimeout: null,
            carouselSettings: {
                arrows: false,
                touchMove: false,
                speed: 1300,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            touchMove: true,
                            speed: 500,
                            centerPadding: '16px',
                        },
                    },
                ],
            },
        }
    },
    computed: {
        airplanes() {
            return airplanesData
        },
    },
    methods: {
        onSwipeStart() {
            if (this.swipingTimeout) {
                clearInterval(this.swipingTimeout)

                this.swipingTimeout = null
            }

            this.isSwiping = true
        },
        onSwipeEnd() {
            this.swipingTimeout = setTimeout(() => {
                this.isSwiping = false
            }, 550)
        },
        showNext() {
            this.$refs.airplanesCarousel.next()
        },
        showPrev() {
            this.$refs.airplanesCarousel.prev()
        },

        setActiveToCloned(newSlide) {
            const slider = this.$refs.airplanesCarousel.$el
            const slides = slider.querySelectorAll('.slick-slide:not(.slick-cloned)')
            let slideParallax

            if (newSlide === 0) {
                slideParallax = slides[0].previousSibling
            } else if (newSlide === slides.length - 1) {
                slideParallax = slides[newSlide].nextSibling
            } else {
                return
            }

            slideParallax.classList.add('slick-parallax')
            setTimeout(() => {
                slideParallax.classList.remove('slick-parallax')
            }, this.$refs.airplanesCarousel.speed)
        },
    },
}
</script>

<style scoped>
.slider__card {
    transition: opacity 150ms;
}
.fade {
    opacity: 0;
}
</style>
