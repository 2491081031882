import services from './ru-RU/services'
import intro from './ru-RU/intro'
import airplanes from './ru-RU/airplanes'
import features from './ru-RU/features'
import aboutUs from './ru-RU/about-us'
import nav from './ru-RU/nav'
import letsFly from './ru-RU/lets-fly'
import faq from './ru-RU/faq'
import fixedInfoBar from './ru-RU/fixed-info-bar'
import langChanger from './ru-RU/lang-changer'
import footer from './ru-RU/footer'
import airplanesPage from './ru-RU/airplanes-page'
import airplanePage from './ru-RU/airplane-page'
import faqPage from './ru-RU/faq-page'
import servicePage from './ru-RU/service-page'

export default {
    ...intro,
    ...services,
    ...airplanes,
    ...features,
    ...aboutUs,
    ...nav,
    ...letsFly,
    ...faq,
    ...fixedInfoBar,
    ...langChanger,
    ...footer,
    ...airplanesPage,
    ...airplanePage,
    ...faqPage,
    ...servicePage,
}
