<template>
    <section class="container features">
        <div class="features__group">
            <h1 class="features__title">
                <WordAnimation
                    :delay="index / 2"
                    v-for="(word, index) of $i18n.messages[$i18n.locale]['features.title']"
                    :key="index"
                    :word="word"
                />
            </h1>
        </div>
        <div class="features__info">
            <HoverAnimation width="640" height="434" img-name="terminal" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.terminal.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.terminal.description')"></p>
                </FadeIn>
            </HoverAnimation>
            <HoverAnimation width="640" height="434" img-name="registration" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.registration.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.registration.description')"></p>
                </FadeIn>
            </HoverAnimation>
            <HoverAnimation width="640" height="434" img-name="waiting" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.personnel.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.personnel.description')"></p>
                </FadeIn>
            </HoverAnimation>
            <HoverAnimation width="640" height="434" img-name="baggage" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.bag.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.bag.description')"></p>
                </FadeIn>
            </HoverAnimation>
            <HoverAnimation width="640" height="434" img-name="food" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.food.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.food.description')"></p>
                </FadeIn>
            </HoverAnimation>
            <HoverAnimation width="640" height="434" img-name="comunication" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.communication.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.communication.description')"></p>
                </FadeIn>
            </HoverAnimation>
            <HoverAnimation width="640" height="434" img-name="animals" class="features__details">
                <FadeIn>
                    <h2 class="features__details-title">
                        <AppIcon class="features__icon" name="star" width="12" height="12" />
                        {{ $t('features.pets.title') }}
                    </h2>
                    <p class="features__description" v-html="$t('features.pets.description')"></p>
                </FadeIn>
            </HoverAnimation>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {},
}
</script>
