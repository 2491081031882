<template>
    <nav class="nav">
        <ul class="nav__list">
            <li class="nav__item">
                <router-link
                    @click.native="onChoose"
                    class="nav__link"
                    exact-active-class="nav__link_active"
                    :to="{ name: 'Home', params: { lang: $i18n.locale } }"
                    v-html="$t('nav.home')"
                ></router-link>
            </li>
            <li class="nav__item">
                <router-link
                    @click.native="onChoose"
                    class="nav__link"
                    active-class="nav__link_active"
                    exact-active-class="nav__link_active"
                    :to="{ name: 'Services', params: { lang: $i18n.locale } }"
                    v-html="$t('nav.services')"
                ></router-link>
            </li>
            <li class="nav__item">
                <router-link
                    @click.native="onChoose"
                    class="nav__link"
                    active-class="nav__link_active"
                    exact-active-class="nav__link_active"
                    :to="{ name: 'Airplanes', params: { lang: $i18n.locale } }"
                    v-html="$t('nav.airplanes')"
                ></router-link>
            </li>
            <li class="nav__item">
                <router-link
                    @click.native="onChoose"
                    class="nav__link"
                    exact-active-class="nav__link_active"
                    :to="{ name: 'Faq', params: { lang: $i18n.locale } }"
                    v-html="$t('nav.faq')"
                ></router-link>
            </li>
            <li class="nav__item">
                <a class="nav__link" href="#contactus" @click="onChoose" v-smooth-scroll v-html="$t('nav.contact')"></a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    methods: {
        onChoose() {
            this.$emit('choose')
        },
    },
}
</script>
