export default {
    'airplanes.title': 'Самолёты',
    'airplanes.g500.description':
        'Cамая большая дальность и скорость полета, самый просторный салон, самые передовые технологии, вплоть до самых больших иллюминаторов и самого низкого перепада давления в салоне относительно уровня моря. Стоит отметить, что, несмотря на наметившийся тренд к широкому использованию композитных материалов, G650 сохранил традиционную металлическую конструкцию.',
    'airplanes.range': 'Дальность полёта',
    'airplanes.seats': 'Число пассажиров',
    'airplanes.speed': 'Максимальная скорость',
    'airplanes.g500.range': '9,816 <small>км</small>',
    'airplanes.g500.seats': '<small>ДО</small> 3',
    'airplanes.g500.speed': '981 <small>км/ч</small>',
    'airplanes.presentation.title': 'ЗАГРУЗИТЬ ПРЕЗЕНТАЦИЮ всех САМОЛЁТОВ',
    'airplanes.download': ' Загрузить ',
    'airplanes.downloadall': ' Загрузить все доступные самолёты ',
    'airplanes.more': ' Подробнее ',
}
