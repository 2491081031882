<template>
    <button @click="$emit('click')" class="hamburger hamburger--slider" :class="{ hamburger_active: open }">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
</template>

<script>
export default {
    name: 'Hamburger',
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<style scoped></style>
