export default {
    'features.title': ['Что вклю<span class="features__title-line">-</span>', 'чено?'],
    'features.terminal.title': 'Терминал',
    'features.terminal.description':
        'Отдельные VIP терминалы в аэропорту позволяют проходить регистрацию всего за пол часа до вылета рейса.',
    'features.registration.title': 'Регистрация',
    'features.registration.description':
        'Преимущества моментальной регистрации на рейс – в отсутствии долгих изнуряющих очередей.',
    'features.personnel.title': 'Ожидание',
    'features.personnel.description': 'Пассажирам доступен VIP-зал для комфортного и приятного ожидания перед вылетом.',
    'features.bag.title': 'Багаж',
    'features.bag.description': 'Размер и вес багажа ограничивается только вместимостью самолёта.',
    'features.food.title': 'Питание',
    'features.food.description': 'Богатое разнообразие изысканных блюд впечатлит даже истинных гурманов.',
    'features.communication.title': 'Коммуникация',
    'features.communication.description':
        'В самолёте доступны все коммуникации для переговоров или работы во время полёта.',
    'features.pets.title': 'Животные',
    'features.pets.description': 'Перевозка ваших домашних питомцев без ограничений в салоне самолёта.',
}
