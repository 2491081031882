<template>
    <div>
        <Intro />
        <Services />
        <Airplanes />
        <Features />
        <AboutUs />
        <FAQ />
    </div>
</template>

<script>
import Intro from '@/components/Intro'
import Services from '@/components/Services'
import Airplanes from '@/components/Airplanes/'
import AboutUs from '@/components/AboutUs'
import FAQ from '@/components/FAQ'
import Features from '@/components/Features'

export default {
    name: 'Home',
    components: {
        Intro,
        Services,
        Airplanes,
        AboutUs,
        FAQ,
        Features,
    },
}
</script>
