export default {
    'airplanes.title': 'Airplanes',
    'airplanes.g500.description':
        'The era of ultramodern aviation has already arrived. Designed from scratch, the Gulfstream G500™ combines excellent flight performance, a comfortable interior and revolutionary technology.',
    'airplanes.range': 'Maximum Range',
    'airplanes.seats': 'Living Areas',
    'airplanes.speed': 'Maximum speed',
    'airplanes.g500.range': '9,816 <small>km</small>',
    'airplanes.g500.seats': '<small>UP TO</small> 3',
    'airplanes.g500.speed': '981 <small>km/h</small>',
    'airplanes.presentation.title': 'Airplanes presentation download',
    'airplanes.download': ' Download ',
    'airplanes.downloadall': ' Download all aviable jets ',
    'airplanes.more': ' More detailed ',
}
