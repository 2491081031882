<template>
    <aside class="info-bar" @mouseenter="dropAnimation" @mouseleave="unsetAnimation">
        <span ref="drop" class="drop"></span>
        <a href="tel:+998781130555" class="info-bar__link"></a>
        <ul class="info-bar__list">
            <li class="info-bar__list-item">
                <div class="info-bar__phone">78 113 05 55</div>
            </li>
            <li class="info-bar__star">
                <AppIcon name="star" width="12" height="12" />
            </li>
            <li class="info-bar__list-item">
                <div class="info-bar__title">{{ $t('info-bar.contact') }}</div>
            </li>
        </ul>
        <AppIcon name="phone2" class="info-bar__icon" width="32" height="32" />
    </aside>
</template>

<script>
import gsap from 'gsap'

export default {
    methods: {
        dropAnimation(e) {
            const { layerX, layerY } = e
            const drop = this.$refs.drop

            gsap.killTweensOf(drop)
            gsap.set(drop, {
                x: layerX,
                y: layerY,
                scale: 0,
                autoAlpha: 1,
            })

            gsap.to(drop, {
                scale: window.innerHeight,
                duration: 4,
            })
        },
        unsetAnimation() {
            const drop = this.$refs.drop

            gsap.to(drop, {
                autoAlpha: 0,
                duration: 1,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.drop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    pointer-events: none;
    transform: translate(-50%, -50%);
    display: block;
    width: 2px;
    height: 2px;
    background-color: rgba(#24262f, 1);
    border-radius: 50%;
}
</style>
