<template>
    <div class="preloader" v-show="animated" :class="{ preloader_animated: animated }">
        <AppIcon class="preloader__img" name="logo" width="134" height="30" />
    </div>
</template>

<script>
export default {
    name: 'Preloader',
    data() {
        return {
            animated: false,
        }
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            if (this.sliceRoute(to).length === this.sliceRoute(from).length) {
                if (this.sliceRoute(to)[0] === this.sliceRoute(from)[0]) {
                    return next()
                }
            }

            this.animated = true

            setTimeout(next, 1000)
            setTimeout(() => {
                this.animated = false
            }, 2000)
        })
    },
    methods: {
        sliceRoute(route) {
            const watchPath = route.fullPath
                .slice(1)
                .split('/')
                .slice(1)

            if (watchPath[watchPath.length - 1] === '') {
                return watchPath.slice(0, -1)
            }

            return watchPath
        },
    },
}
</script>
