export default {
    'features.title': ['Nimalar', 'kiradi?'],
    'features.terminal.title': 'VIP terminal',
    'features.terminal.description': 'Biznes aviatsiya uchun aeroportida individual VIP terminallar.',
    'features.registration.title': 'Rõyxatdan õtish',
    'features.registration.description': 'Navbatni kutmasdan uchish uchun darhol rõyxatdan õtish.',
    'features.personnel.title': 'Xodimlar',
    'features.personnel.description':
        'Sizning qulayligingiz uchun samolyotda xizmat kõrsatuvchi xodimlar bõladi, yõlovchilar soniga qarab, ularning soni ortadi',
    'features.bag.title': 'Yuk',
    'features.bag.description': 'Yukingizning kattaligi va vazni faqat samolyot hajmi bilan cheklangan.',
    'features.food.title': 'Oziq-ovqat',
    // eslint-disable-next-line prettier/prettier
    'features.food.description': 'Sizning ta\'mingiz uchun dunyodagi turli xil oshxonalarning tõliq idishlari.',
    'features.communication.title': 'Aloqa',
    'features.communication.description': 'Samolyotda parvoz paytida muzokaralar yoki ish uchun barcha aloqa mavjud.',
    'features.pets.title': 'Hayvonlar',
    'features.pets.description': 'Xususiy samolyotda siz uy hayvonlarini tashish huquqiga egasiz.',
}
