export default {
    'features.title': ['What is', 'included?'],
    'features.terminal.title': 'VIP terminal',
    'features.terminal.description':
        'For the most comfortable pastime, we reserve a special VIP terminal, with a chic seating area and a car to the plane',
    'features.registration.title': 'Check in',
    'features.registration.description': 'Instant check-in for the flight, without waiting for queues.',
    'features.personnel.title': 'personnel',
    'features.personnel.description':
        'For your convenience, there will be service personnel on the plane, depending on the number of passengers, the number will increase',
    'features.bag.title': 'Bag',
    'features.bag.description': 'The size and weight of the baggage is limited only by the capacity of the aircraft.',
    'features.food.title': 'Food',
    'features.food.description':
        'Freshly prepared food that is prepared according to all the standards of European cuisine. You can also choose from cuisines from all over the world.',
    'features.communication.title': 'Communication',
    'features.communication.description':
        'All communications are available on the plane for negotiations or work during the flight.',
    'features.pets.title': 'Pets',
    'features.pets.description': 'In a private plane, you have the right to transport pets.',
}
