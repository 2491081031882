<template>
    <div id="app" class="page-wrapper">
        <Preloader />
        <FixedInfoBar />
        <Header />
        <main>
            <router-view />
            <LetsFly />
        </main>
        <Footer />
        <template v-if="!isPrerender">
            <div id="fb-root"></div>
            <div id="fb-customer-chat" class="fb-customerchat"></div>
        </template>
    </div>
</template>

<script>
import Preloader from '@/components/Preloader'
import FixedInfoBar from '@/components/FixedInfoBar'
import Header from '@/components/Header'
import LetsFly from '@/components/LetsFly'
import Footer from '@/components/Footer'

export default {
    components: {
        Preloader,
        FixedInfoBar,
        Header,
        LetsFly,
        Footer,
    },

    computed: {
        isPrerender() {
            return window.__PRERENDERING !== undefined
        },
    },

    mounted() {
        if (!this.isPrerender) {
            this.createFB()
        }
    },

    methods: {
        createFB() {
            // Facebook Pixel Code
            !((f, b, e, v, n, t, s) => {
                if (f.fbq) {
                    return
                }

                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }

                if (!f._fbq) {
                    f._fbq = n
                }

                n.push = n
                n.loaded = !0
                n.version = '2.0'
                n.queue = []
                t = b.createElement(e)
                t.async = !0
                t.src = v
                s = b.getElementsByTagName(e)[0]
                s.parentNode.insertBefore(t, s)
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
            // eslint-disable-next-line no-undef
            fbq('init', '502949490786459')
            // eslint-disable-next-line no-undef
            fbq('track', 'PageView')
            // / Facebook Pixel Code

            // Facebook Live Chat
            const chatbox = document.getElementById('fb-customer-chat')

            chatbox.setAttribute('logged_out_greeting', 'Добрый день! Могу ли я вам помочь?')
            chatbox.setAttribute('logged_in_greeting', 'Добрый день! Могу ли я вам помочь?')
            chatbox.setAttribute('theme_color', '#c1995b')
            chatbox.setAttribute('page_id', process.env.VUE_APP_LIVE_CHAT_PAGE_ID)
            chatbox.setAttribute('attribution', 'biz_inbox')

            window.fbAsyncInit = () => {
                // eslint-disable-next-line no-undef
                FB.init({
                    xfbml: true,
                    version: 'v11.0',
                })
            }
            ;((d, s, id) => {
                let js = null
                const fjs = d.getElementsByTagName(s)[0]

                if (d.getElementById(id)) {
                    return
                }

                js = d.createElement(s)
                js.id = id
                js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js'
                js.async = true
                js.referrerPolicy = window.location.origin + '/'
                fjs.parentNode.insertBefore(js, fjs)
            })(document, 'script', 'facebook-jssdk')
            // Facebook Live Chat
        },
    },
}
</script>

<style lang="scss" src="@/assets/scss/main.scss" />
