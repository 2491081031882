<template>
    <section class="airplanes" id="airplanes">
        <div class="container airplanes__container">
            <h1 class="airplanes__title"><WordAnimation :word="$t('airplanes.title')" /></h1>
        </div>
        <FadeIn>
            <AirplanesSlider />
        </FadeIn>
        <FadeIn class="container airplanes__presentation">
            <h2 class="airplanes__presentation-title" v-html="$t('airplanes.presentation.title')"></h2>
            <AppButton href="/real_avia_jets.pdf" download tag="a" size="md-min">{{
                $t('airplanes.download')
            }}</AppButton>
        </FadeIn>
    </section>
</template>

<script>
import AirplanesSlider from './AirplanesSlider'

export default {
    components: {
        AirplanesSlider,
    },
    data() {
        return {}
    },
    methods: {},
}
</script>
