export default {
    'faq.title': ['Answers', 'to your', 'questions'],
    'faq.question_1': 'Which countries can I fly to now?',
    'faq.answer_1':
        'With Jet Partners, you can fly to any country in the world with the necessary grounds, as well as to France, Cyprus and the United Arab Emirates only with a foreign passport and a valid tourist visa.',
    'faq.question_2': 'What documents are required for flights abroad?',
    'faq.answer_2':
        'If we talk about our under-seat programs under the Jet SharingTM system, then only a foreign passport and a Schengen visa. If the directions where you fly on the jet individually – then foreign citizenship or',
    'faq.button': ' Show more ',
}
