import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/locales'

import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
    {
        path: '/:lang',
        component: {
            render: (c) => c('router-view'),
        },
        beforeEnter: (to, from, next) => {
            const supportedLanguages = Object.keys(i18n.messages)

            if (supportedLanguages.includes(to.params.lang)) {
                return next()
            }

            next('/' + i18n.locale)
        },
        children: [
            {
                path: '',
                name: 'Home',
                component: Home,
            },
            {
                path: 'faq',
                name: 'Faq',
                component: () => import('@/views/Faq'),
            },
            {
                path: 'airplanes',
                component: () => import('@/views/Airplanes/AirplaneWrapper'),

                children: [
                    {
                        path: ':airplaneName',
                        name: 'Airplane',
                        component: () => import('@/views/Airplanes/Airplane'),
                    },
                    {
                        path: '/',
                        name: 'Airplanes',
                        component: () => import('@/views/Airplanes/Airplanes'),
                    },
                ],
            },
            {
                path: 'services',
                name: 'Services',
                component: {
                    render: (c) => c('router-view'),
                },
                redirect: () => {
                    return { name: 'Service', params: { lang: i18n.locale, serviceName: 'jets-rent' } }
                },

                children: [
                    {
                        path: ':serviceName',
                        name: 'Service',
                        component: () => import('@/views/Services/Service'),
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        redirect: '/' + i18n.locale,
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default router
