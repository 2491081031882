export default {
    'faq.title': ['Ответы', 'на ваши', 'вопросы'],
    'faq.question_1': 'Какие документы необходимы для аренды самолёта?',
    'faq.answer_1':
        'Клиент должен предъявить оригинал паспорта.  Если у него есть дети, то нужно предоставить их свидетельства о рождении. В случае отправления несовершеннолетнего ребенка в сопровождении иных родственников родители должны подтвердить свое согласие нотариально заверенной доверенностью. Гражданам с ограниченными физическими возможностями следует представить справку об инвалидности. После проверки деловых бумаг компания заключает типовой договор с пассажиром об оказании услуг.',
    'faq.question_2': 'Чем чартерный авиарейс отличается от регулярного?',
    'faq.answer_2':
        'Чартерный рейс выполняется по заказу конкретного клиента и по индивидуальному маршруту. Перелёт осуществляется даже в том случае, если с пунктом назначения не налажено регулярное авиасообщение. Время вылета также оговаривается, оно не привязано к расписанию регулярных рейсов.',
    'faq.button': ' Показать больше ',
}
