import Vue from 'vue'
import VueI18n from 'vue-i18n'

// default lang
import ruRU from './lang/ru-RU'
import enUS from './lang/en-US'
import uzUZ from './lang/uz-UZ'

Vue.use(VueI18n)

const messages = {
    'ru-RU': {
        ...ruRU,
    },

    'en-US': {
        ...enUS,
    },

    'uz-UZ': {
        ...uzUZ,
    },
}

const navigatorLanguage = 'ru-RU'

// Object.keys(messages).forEach((lang) => {
//     if (lang.includes(navigator.language) || navigator.language.includes(lang)) {
//         navigatorLanguage = lang
//     }
// })

export const defaultLang = navigatorLanguage

const i18n = new VueI18n({
    silentTranslationWarn: true,
    locale: defaultLang,
    fallbackLocale: defaultLang,
    messages,
    interpolation: { escapeValue: false },
})

const loadedLanguages = [defaultLang]

function setI18nLanguage(lang) {
    i18n.locale = lang
    // request.headers['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)

    return lang
}

export function loadLanguageAsync(lang = defaultLang) {
    return new Promise((resolve) => {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "lang-[request]" */ `./lang/${lang}`).then((msg) => {
                const locale = msg.default

                i18n.setLocaleMessage(lang, locale)
                loadedLanguages.push(lang)

                return setI18nLanguage(lang)
            })
        }

        return resolve(setI18nLanguage(lang))
    })
}

export function i18nRender(key) {
    return i18n.t(`${key}`)
}

export default i18n
