<template>
    <header class="header page-wrapper__header" :class="{ header_open: isOpenMenu, header_state_active: isActive }">
        <div class="header__container container">
            <div class="header__logo-wrapper">
                <router-link :to="{ name: 'Home', params: { lang: this.$i18n.locale } }">
                    <AppIcon class="header__logo" name="logo" width="134" height="30" />
                </router-link>
                <div class="header__logo-inner">
                    <div class="header__logo-title">Menu</div>
                    <AppIcon class="header__icon" name="star" width="12" height="12" />
                    <Hamburger @click="isOpenMenu = !isOpenMenu" :open="isOpenMenu" class="header__hamburger" />
                </div>
            </div>
            <div class="header__menu">
                <Nav class="header__nav" @choose="onNavClick" />
                <AppIcon class="header__icon" name="star" width="12" height="12" />
                <LangChanger />
            </div>
        </div>
    </header>
</template>

<script>
import LangChanger from '@/components/LangChanger'
import Nav from '@/components/Nav'
import Hamburger from '@/components/Hamburger'

export default {
    components: {
        LangChanger,
        Nav,
        Hamburger,
    },

    data() {
        return {
            isActive: false,
            isOpenMenu: false,
            scrollTop: 0,
        }
    },

    watch: {
        isOpenMenu: function(oldValue, newValue) {
            if (newValue) {
                this.removeOverflow()
            } else {
                this.setOverflow()
            }
        },
    },

    created() {
        window.addEventListener('scroll', this.onScroll)
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        openMenu() {
            this.isOpenMenu = true
        },
        closeMenu() {
            this.isOpenMenu = false
        },
        onNavClick() {
            this.scrollTop = 0
            this.closeMenu()
        },
        onScroll() {
            if (window.scrollY <= 0) {
                this.isActive = false
            } else {
                this.isActive = true
            }
        },
        setOverflow() {
            this.scrollTop = window.scrollY

            document.documentElement.classList.add('overflow-hidden')
        },
        removeOverflow() {
            document.documentElement.classList.remove('overflow-hidden')

            window.scrollTo(0, this.scrollTop)
        },
    },
}
</script>
