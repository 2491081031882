import services from './uz-UZ/services'
import intro from './uz-UZ/intro'
import airplanes from './uz-UZ/airplanes'
import features from './uz-UZ/features'
import aboutUs from './uz-UZ/about-us'
import nav from './uz-UZ/nav'
import letsFly from './uz-UZ/lets-fly'
import faq from './uz-UZ/faq'
import fixedInfoBar from './uz-UZ/fixed-info-bar'
import langChanger from './uz-UZ/lang-changer'
import footer from './uz-UZ/footer'
import airplanesPage from './uz-UZ/airplanes-page'
import airplanePage from './uz-UZ/airplane-page'
import faqPage from './uz-UZ/faq-page'
import servicePage from './uz-UZ/service-page'

export default {
    ...intro,
    ...services,
    ...airplanes,
    ...features,
    ...aboutUs,
    ...nav,
    ...letsFly,
    ...faq,
    ...fixedInfoBar,
    ...langChanger,
    ...footer,
    ...airplanesPage,
    ...airplanePage,
    ...faqPage,
    ...servicePage,
}
