import { APP_LANGUAGE } from '@/store/mutation-types'
import { defaultLang, loadLanguageAsync } from '@/locales'

const app = {
    namespaced: true,

    state: {
        lang: defaultLang,
    },

    mutations: {
        [APP_LANGUAGE]: (state, lang = {}) => {
            state.lang = lang
        },
    },

    actions: {
        setLang({ commit }, lang) {
            return new Promise((resolve, reject) => {
                commit(APP_LANGUAGE, lang)
                loadLanguageAsync(lang)
                    .then(() => {
                        resolve()
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
    },
}

export default app
