<template>
    <div v-click-outside="hideDropdown" class="lang-changer">
        <div class="lang-changer__inner">
            <button @click="toggleDropdown" class="lang-changer__head">
                {{ $t('lang-changer.title') }}: <mark>{{ activeLang }}</mark>
            </button>
            <ul class="lang-changer__dropdown" :class="{ 'lang-changer__dropdown_active': isShowDropdown }">
                <li class="lang-changer__item" v-for="(item, index) of languages" :key="index">
                    <button
                        @click="changeLanguage(item)"
                        :class="{ 'lang-changer__button_active': item.active }"
                        class="lang-changer__button"
                    >
                        {{ item.title }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            isShowDropdown: false,
            activeLang: null,
            languages: [
                {
                    title: 'Русский язык',
                    code: 'rus',
                    lang: 'ru-RU',
                },
                {
                    title: 'English',
                    code: 'eng',
                    lang: 'en-US',
                },
                {
                    title: 'O’zbek tili',
                    code: 'uzb',
                    lang: 'uz-UZ',
                },
            ],
        }
    },
    computed: {
        ...mapState('app', ['lang']),
    },
    watch: {
        '$route.params.lang': {
            handler: function() {
                this.setDefaultLang()
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions('app', ['setLang']),
        changeLanguage(langItem) {
            this.activeLang = langItem.code

            this.languages.map((item) => (item.active = false))
            langItem.active = true

            this.setLang(langItem.lang)

            if (this.$route.params.lang !== langItem.lang && this.$route.name) {
                const to = this.$router.resolve({ params: { lang: langItem.lang } })

                this.$router.push(to.location)
            }

            this.hideDropdown()
        },
        toggleDropdown() {
            this.isShowDropdown = !this.isShowDropdown
        },
        hideDropdown() {
            if (this.isShowDropdown === true) {
                this.isShowDropdown = false
            }
        },
        setDefaultLang() {
            let defaultLang

            if (this.$route.params.lang) {
                defaultLang = this.getLangItem(this.$route.params.lang)
            } else {
                defaultLang = this.getLangItem(this.lang)
            }

            this.changeLanguage(defaultLang)
        },
        getLangItem(langCode) {
            return this.languages.find((item) => item.lang === langCode)
        },
    },
}
</script>
