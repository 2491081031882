export default {
    'airplanes.title': 'Samolyotlar',
    'airplanes.g500.description':
        'Eng katta masofa va parvoz tezligi, eng keng salon, eng ilg\'or texnologiyalar, eng katta yoritgichlarga va eng past salonda dengiz sathiga nisbatan bosim farqi. Ta\'kidlash joizki, kompozit materiallardan keng foydalanish tendentsiyasiga qaramasdan, G650 an\'anaviy metall konstruktsiyasini saqlab qoldi.',
    'airplanes.range': 'Parvoz oralig\'i',
    'airplanes.seats': 'Yõlovchilar soni',
    'airplanes.speed': 'Maksimal tezlik',
    'airplanes.g500.range': '9,816 <small>km</small>',
    'airplanes.g500.seats': '3 <small>ta</small>',
    'airplanes.g500.speed': '981 <small>km/s</small>',
    'airplanes.presentation.title': 'Barcha samolyotlar taqdimotini yuklab oling',
    'airplanes.download': ' Yuklab olish ',
    'airplanes.downloadall': ' Barcha mavjud samolyotlarni yuklab oling ',
    'airplanes.more': ' Batafsil ',
}
