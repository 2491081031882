<template>
    <section class="container services" id="services">
        <h1 class="services__title"><WordAnimation :word="$t('services.title')" /></h1>
        <div class="services__cards">
            <div class="services__card gallery" v-for="(service, index) of services" :key="service.id">
                <FadeIn class="services__info" :delay="index / 3">
                    <h2 class="services__subtitle">
                        <AppIcon class="services__icon" name="star" width="12" height="12" />
                        {{ service.title }}
                    </h2>
                    <p class="gallery__description">{{ service.excerptDescription }}</p>
                </FadeIn>
                <router-link
                    :to="{ name: 'Service', params: { lang: $i18n.locale, serviceName: service.img } }"
                    class="gallery__image-wrapper"
                >
                    <ImgAnimation :delay="index / 3">
                        <img
                            class="gallery__image"
                            :src="require(`@/assets/images/services/${service.img}/preview.jpg`)"
                            alt=""
                        />
                    </ImgAnimation>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
const serviceData = require('@/views/Services/services.json')

export default {
    data: () => {
        return {
            services: serviceData,
        }
    },
}
</script>
