<template>
    <ul class="stats">
        <li v-for="(item, index) of statsList" :key="index" class="stats__item">
            <h2 class="stats__title" v-html="item.title['ru']" />
            <p class="stats__description">{{ item.description['ru'] }}</p>
            <AppIcon class="stats__icon" name="star" width="12" height="12" />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Stats',
    props: {
        statsList: {
            type: Array,
            required: true,
        },
    },
}
</script>
